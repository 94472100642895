/* Nestle */
@font-face {
  font-family: 'NexaRustScript';
  font-style: normal;
  font-weight: bold;
  src: url('/fonts/NexaRustScript.otf') format('opentype');
}
@font-face {
  font-family: 'NexaRustSansBlack';
  font-style: normal;
  font-weight: 900;
  src: url('/fonts/NexaRustSans-Black.otf') format('opentype');
}
@font-face {
  font-family: 'Nexalight';
  font-style: normal;
  font-weight: bold;
  src: url('/fonts/Nexa-Light.otf') format('opentype');
}
